// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../../packages/framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../../packages/framework/src/Utilities";
import createRequestMessage from "../../../../packages/blocks/utilities/src/create-request-message";

export const configJSON = require("./config.js");

export type Props =  {
    id: string;
    isDialogOpen: boolean
    onCloseDialog: (data?: Array<File>) => void
    classes?: Record<string, string>
    files: any
}

interface S {
    files: Array<File>
    currentFile?: File
    action: string;
    isCancel: boolean
    phase: number
    nameFile: string
    descriptionFile: string
    fileData: string
    fileFormat: string
    currentTab: number
    listWorkspace: Array<{ id: number; name: string }>
    workspaceChoice: string
}

interface SS {
  id: string | number;
}

class DashboardPreviewFileController extends BlockComponent<Props, S, SS> {
    getWorkspaceListId: string = ""
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            files: [],
            listWorkspace: [],
            currentFile: undefined,
            action: "",
            isCancel: false,
            phase: 1,
            nameFile: "",
            descriptionFile: "",
            fileData: "",
            currentTab: 0,
            workspaceChoice: "",
            fileFormat: ""
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson) {
                if (apiRequestCallId === this.getWorkspaceListId) {
                    this.setState({ listWorkspace: responseJson })
                }
            }
        }
        // Customizable Area End
    }

    async componentDidMount(): Promise<void> {
        this.getWorkspaceList()
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
        if (prevProps.files.length !== this.props.files.length) {
            this.setState({ files: this.props.files, currentFile: this.props.files[0] })
        }
    }
  
    buildReportAPI = async (currentFile: File) => {
      const data = new FormData();
      data.append( "file", currentFile);
      fetch(`${configJSON.urlClientServer}/${configJSON.buildReport}`, { method: 'POST', body: data })
    }

    onCloseAll = () => {
        if (this.state.currentTab === 0) {
            this.setState({ currentTab: 1 })
            Promise.all(this.state.files.map(file => {
              this.buildReportAPI(file)
            }))
        } else {
            this.setState({ isCancel: false }, () => {
                this.closeDialog()
            })
        }
    }

    closeDialog = () => {
        this.setState({
            action: "",
            isCancel: false,
            phase: 1,
            nameFile: "",
            descriptionFile: "",
            fileData: "",
            currentTab: 0,
            workspaceChoice: "",
            fileFormat: ""
        })
        this.props.onCloseDialog()
    }

    getWorkspaceList = async () => {
        const request = new Message(getName(MessageEnum.RestAPIRequestMessage))
        const token = await getStorageData("authToken")
        this.getWorkspaceListId = request.messageId;
        createRequestMessage({
            method: "GET",
            requestMessage: request,
            token,
            endPoint: `${configJSON.getListWorkspace}`
        });
    }

    onBackToPhase1 = () => {
        this.setState({ phase: 1 })
    }

    handleChange = (event: any) => {
        this.setState({ fileData: event.target.value });
    };

    handleChangeAction = (event: any) => {
        this.setState({ action: event.target.value });
    };

    handleChangeFormat = (event: any) => {
        this.setState({ fileFormat: event.target.value });
    };

    onCloseCancelDialog = () => {
        this.setState({ isCancel: false })
    }

    onOpenCancelDialog = () => {
        this.setState({ isCancel: true })
    }

    onClickButton = () => {
        this.setState({ phase: 2 })
    }

    onChangeName = (nameFile: string) => {
        this.setState({ nameFile })
    }

    onChangeDescription = (descriptionFile: string) => {
        this.setState({ descriptionFile })
    }

    onChangeWorkspace = (workspaceChoice: { id: number; name: string }) => {
        this.setState({ workspaceChoice: workspaceChoice.name })
    }
}

export default DashboardPreviewFileController;
// Customizable Area End
