// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import createRequestMessage from "../../utilities/src/create-request-message";
import { baseURL } from "../../bulkuploading/src/BulkUploadingController";
import { getStorageData } from "../../../framework/src/Utilities";

export type resultDataType = {
    id?: string | number;
    category?: string;
    description?: string;
    amount?: string;
}

export const configJSON = require("./config.js");

export type Props = {
    classes?: Record<string, string>
    workspaceId: number
    currentUserId: string
}

interface S {
    resultList?: resultDataType[];
}

interface SS {
    id: string | number;
}

class ResultTabController extends BlockComponent<Props, S, SS> {
    getListResultId: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            resultList: [],
            
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    receive = (from: string, message: Message) => {
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getListResultId) {

                if(responseJson?.error){
                    this.setState({
                        resultList: [
                            {
                                id: '1',
                                category: 'Business Insurance',
                                description: '- General liability insurance: Covers third-party claims for bodily injury or property damage.',
                                amount: '$50,000/year'
                            },
                            {
                                id: '2',
                                category: 'Commercial Property',
                                description: '- Property insurance: Covers physical assets (buildings, equipment, inventory) against damage or loss',
                                amount: '$2 million (property coverage) $500,000 (business interruption)'
                            },
                            {
                                id: '3',
                                category: 'Commercial Property 3',
                                description: '- Property insupment, inventory) against damage or loss  3',
                                amount: '$2 million (prnterruption)3'
                            },
                            {
                                id: '4',
                                category: 'Commercial Property 4',
                                description: '- Property insupment, inventory) against damage or loss  4',
                                amount: '$20 million (prnterruption)'
                            },
                            {
                                id: '5',
                                category: 'Commercial Property 5',
                                description: '- Property insupment, inventory) against damage or loss  5',
                                amount: '$120 million (prnterruption)'
                            },
                        ]
                    });
                } else {
                    this.setState({ resultList: (responseJson?.data) })
                }
            }
        }
    }


    getResultListData = async () => {
        const token = await getStorageData("authToken")
        const request = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.getListResultId = request.messageId;
        createRequestMessage({
            token,
            requestMessage: request,
            method: "GET",
            endPoint: `${baseURL}/${configJSON.getResultList}`
        });
    }


    async componentDidMount(): Promise<void> {
        this.getResultListData()
    }
}

export default ResultTabController;
// Customizable Area End
