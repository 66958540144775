// Customizable Area Start
// @ts-ignore
import FileViewer from 'react-file-viewer';
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import DashboardPreviewFileController from "./DashboardPreviewFileController.web";
import { Box, Button, Dialog, DialogContent, FormControl, Grid, InputAdornment, MenuItem, Tabs, TextField, Typography } from "@material-ui/core";
import { sampleFile } from "./assets";
import { SelectComponent } from "../../../../packages/blocks/accountgroups/src/WorkspaceList.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ConfirmationDialog from '../../../../packages/blocks/accountgroups/src/ConfirmationDialog.web';
import { Autocomplete } from '@material-ui/lab';
import MuiTab from "@material-ui/core/Tab";
import { filterOptions } from './BulkUploadDashboardDialog.web';
import ResultTab from '../../../../packages/blocks/accountgroups/src/ResultTab.web';
const configJSON = require("./config");

export class DashboardPreviewFile extends DashboardPreviewFileController {
  renderFilePreview = (file: File) => {
    if (file.type.includes("image") && file.lastModified !== -1) {
      const filePreview = {
        ...file,
        preview: URL.createObjectURL(file),
      }
      return <img src={filePreview.preview} style={styles.fileIcon} />
    } else {
      return <img src={sampleFile} style={styles.fileIcon} />
    }
  }

  renderDropdownAction = () => {
    return (
      <FormControl variant="filled">
        <SelectComponent
          data-test-id={`renderDropdownAction`}
          className={this.props.classes?.customdropdown}
          displayEmpty
          renderValue={value => (this.state.action !== "" ? value : "Choose option") as React.ReactNode}
          style={{
              color: this.state.action !== "" ? "#30353B" : "#94A3B8",
          }}
          value={this.state.action}
          IconComponent={() => <div />}
          endAdornment={(
            <InputAdornment position="end" className={this.props.classes?.viewIconDropdown}>
              <ExpandMoreIcon style={styles.iconExpand} />
            </InputAdornment>
          )}
          MenuProps={{
            transformOrigin: {
              vertical: "top",
              horizontal: "right"
            },
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right"
            },
          }}
          onChange={this.handleChangeAction}
        >
          <MenuItem
            className={this.props.classes?.dropdownitem}
            value="Merged files"
            data-test-id="Merged files"
          >
            Merged files
          </MenuItem>
          <MenuItem
            className={this.props.classes?.dropdownitem}
            value="Detect information from files"
            data-test-id="Detect information from files"
          >
            Detect information from files
          </MenuItem>
        </SelectComponent>
      </FormControl>
    )
  }

  renderDropdown = () => {
    return (
      <FormControl variant="filled">
        <SelectComponent
          data-test-id={`renderDropdown`}
          className={this.props.classes?.customdropdown}
          renderValue={value => (this.state.fileData !== "" ? value : "Choose a final data format") as React.ReactNode}
          style={{
              color: this.state.fileData !== "" ? "#30353B" : "#94A3B8",
          }}
          value={this.state.fileData}
          IconComponent={() => <div />}
          endAdornment={(
            <InputAdornment position="end" className={this.props.classes?.viewIconDropdown}>
              <ExpandMoreIcon style={styles.iconExpand} />
            </InputAdornment>
          )}
          displayEmpty={true}
          MenuProps={{
            transformOrigin: {
              vertical: "top",
              horizontal: "right"
            },
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right"
            },
          }}
          onChange={this.handleChange}
        >
          <MenuItem
            className={this.props.classes?.dropdownitem}
            value="Table"
            data-test-id="Table"
          >
            Table
          </MenuItem>
        </SelectComponent>
      </FormControl>
    )
  }

  renderDropdownFormat = () => {
    return (
      <FormControl variant="filled">
        <SelectComponent
          data-test-id={`renderDropdownFormat`}
          className={this.props.classes?.customdropdown}
          renderValue={value => (this.state.fileFormat !== "" ? value : "Choose a final file format") as React.ReactNode}
          style={{
              color: this.state.fileFormat !== "" ? "#30353B" : "#94A3B8",
          }}
          value={this.state.fileFormat}
          IconComponent={() => <div />}
          endAdornment={(
            <InputAdornment position="end" className={this.props.classes?.viewIconDropdown}>
              <ExpandMoreIcon style={styles.iconExpand} />
            </InputAdornment>
          )}
          displayEmpty={true}
          MenuProps={{
            transformOrigin: {
              vertical: "top",
              horizontal: "right"
            },
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right"
            },
          }}
          onChange={this.handleChangeFormat}
        >
          <MenuItem
            className={this.props.classes?.dropdownitem}
            value="XML"
            data-test-id="XML"
          >
            XML
          </MenuItem>
        </SelectComponent>
      </FormControl>
    )
  }

  renderCurrentFile = () => {
    if (this.state.currentFile) {
      const type = this.state.currentFile.name.split(".")
      return (
        <FileViewer
          fileType={type[type.length - 1]}
          filePath={this.state.currentFile.lastModified !== -1 ? URL.createObjectURL(this.state.currentFile) : this.state.currentFile.name}
        />
      )
    } else return <div />
  }

  checkEnableButton = () => {
    if (this.state.currentTab === 0) {
      if (this.state.fileData !== "" && this.state.nameFile !== "" && this.state.descriptionFile !== "") {
        return true
      } else return false
    } else {
      if (
        this.state.fileData !== "" &&
        this.state.nameFile !== "" &&
        this.state.descriptionFile !== "" &&
        this.state.fileFormat !== "" &&
        this.state.workspaceChoice !== ""
      ) {
        return true
      } else return false
    }
  }

  renderForm = () => {
    const { classes } = this.props
    if (this.state.phase === 1) {
      return (
        <div className={classes?.rightSide}>
            <div style={styles.column}>
            <Typography style={styles.rightText}>Please set processing parameters</Typography>
            <div style={styles.column}>
              <Typography style={styles.dropdowntext}>Action to process with AI  </Typography>
              {this.renderDropdownAction()}
            </div>
          </div>
          <Button
            data-test-id="buttonPhase1"
            variant="contained"
            color="primary"
            fullWidth
            style={{ ...styles.buttonProceed, ... { backgroundColor: !this.state.action ? '#94A3B8' : '#684EF3', } }}
            disabled={!this.state.action}
            onClick={this.onClickButton}
          >
            Continue
          </Button>
        </div>
      )
    } else {
      return (
        <div className={classes?.rightSide}>
          <div style={styles.column}>
            <div style={styles.column}>
              <Typography style={styles.rightText}>Please set processing parameters</Typography>
              <Typography style={styles.rightSmallText}>After proceeding the result, please adjust it if required.</Typography>
              {this.renderInput(
                "Final file name",
                this.state.nameFile,
                "Final file name",
                this.onChangeName,
                false
              )}
              <Typography style={styles.dropdowntext}>Final data format</Typography>
              {this.renderDropdown()}
              <div style={{ height: "20px" }} />
              {this.renderInput(
                "Please describe information you wish to collect from uploaded files",
                this.state.descriptionFile,
                "Please describe information you wish to collect from uploaded files",
                this.onChangeDescription,
                true
              )}
            </div>
          </div>
          {
            this.state.currentTab === 1 && (
              <div style={styles.viewJSON}>
                <div style={styles.buttonRegenerate}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 10.9997H7.101L7.102 10.9907C7.23257 10.3514 7.48813 9.74409 7.854 9.20374C8.39845 8.40156 9.16215 7.7729 10.054 7.39274C10.356 7.26474 10.671 7.16674 10.992 7.10174C11.6579 6.96674 12.3441 6.96674 13.01 7.10174C13.967 7.29784 14.8451 7.77116 15.535 8.46274L16.951 7.05074C16.3128 6.41238 15.5578 5.90278 14.727 5.54974C14.3033 5.37037 13.8628 5.2337 13.412 5.14174C12.4818 4.95275 11.5232 4.95275 10.593 5.14174C10.1419 5.23408 9.70101 5.37108 9.277 5.55074C8.02753 6.08084 6.95793 6.96083 6.197 8.08474C5.68489 8.84259 5.32676 9.69373 5.143 10.5897C5.115 10.7247 5.1 10.8627 5.08 10.9997H2L6 14.9997L10 10.9997ZM14 12.9997H16.899L16.898 13.0077C16.6367 14.2895 15.8812 15.4168 14.795 16.1457C14.2548 16.5119 13.6475 16.7675 13.008 16.8977C12.3424 17.0327 11.6566 17.0327 10.991 16.8977C10.3516 16.7672 9.74435 16.5116 9.204 16.1457C8.93862 15.9662 8.69085 15.762 8.464 15.5357L7.05 16.9497C7.68851 17.5879 8.44392 18.0972 9.275 18.4497C9.699 18.6297 10.142 18.7667 10.59 18.8577C11.5198 19.0468 12.4782 19.0468 13.408 18.8577C15.2005 18.4857 16.7773 17.4292 17.803 15.9127C18.3146 15.1555 18.6724 14.305 18.856 13.4097C18.883 13.2747 18.899 13.1367 18.919 12.9997H22L18 8.99974L14 12.9997Z" fill="#684EF3" />
                  </svg>
                  Regenerate
                </div>
                <div style={styles.viewInput}>
                  <span style={styles.titleTextInput}>{configJSON.workspaceChoice}</span>
                  {this.renderWorkspaceChoice()}
                </div>
                <div style={styles.viewInput}>
                  <span style={styles.titleTextInput}>{"Final file format"}</span>
                  {this.renderDropdownFormat()}
                </div>
              </div>
            )
          }
          <Button
            data-test-id="buttonPhase2"
            variant="contained"
            color="primary"
            fullWidth
            style={{ ...styles.buttonProceed, ... { backgroundColor: !this.checkEnableButton() ? '#94A3B8' : '#684EF3', } }}
            disabled={!this.checkEnableButton()}
            onClick={this.onCloseAll}
          >
            {"Proceed"}
          </Button>
        </div>
      )
    }
  }

  renderWorkspaceChoice = () => {
    return (
      <Autocomplete
        data-test-id="autocomplete"
        options={this.state.listWorkspace}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={configJSON.workspacePlaceholder}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              style: styles.placeholderButtonShowWorkspace,
            }}
          />
        )}
        onChange={(event, inputVal) => {
          if (inputVal) this.onChangeWorkspace(inputVal)
        }}
        renderOption={(option) => (
          <div style={styles.optionItem}>{option.name}</div>
        )}
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option, value) => option.name === value.name}
        filterOptions={filterOptions}
      />
    )
  }

  renderInput = (
    title: string,
    value: string,
    placeholder: string,
    onChangeValue: (value: string) => void,
    multiline?: boolean
  ) => {
    return (
      <div style={styles.inputView}>
        <div style={styles.titleText}>{title}</div>
        <TextField
          className={multiline? this.props.classes?.inputTextMultiline : this.props.classes?.inputText}
          data-test-id={`inputText_${title}`}
          value={value}
          onChange={(event) => onChangeValue(event.target.value)}
          placeholder={placeholder}
          InputProps={{
            disableUnderline: true,
            className: this.props.classes?.searchStyle
          }}
          multiline={multiline}
        />
      </div>
    )
  }

  handlePhase = () => {
    if (this.state.currentTab === 0) {
      return this.renderCurrentFile()
    } else {
      return <ResultTab workspaceId={0} currentUserId='1' />
    }
  }

  render() {
    const { classes, isDialogOpen } = this.props
    return (
      <Dialog
        onClose={() => this.closeDialog()}
        aria-labelledby="customized-dialog-title"
        open={isDialogOpen}
        fullWidth
        PaperProps={{
          style: styles.viewDialog
        }}
      >
        <DialogContent
          className="dialog-address-content"
          style={{ padding: 0 }}
        >
          <Box style={styles.header}>
            {
              this.state.phase === 1 
              ? <Typography style={styles.headerText}>Processing files</Typography>
              : <Typography data-test-id="backtosignuptext" style={styles.headerText} onClick={this.onBackToPhase1}>
                  <svg style={styles.iconBack} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="32" height="32" rx="16" fill="#30353B"/>
                    <path d="M24.9999 15H10.4139L15.7069 9.70697L14.2929 8.29297L6.58594 16L14.2929 23.707L15.7069 22.293L10.4139 17H24.9999V15Z" fill="white"/>
                  </svg>
                  {`Processing ${this.state.files.length} ${this.state.files.length > 1 ? "files" : "file"}`}
                </Typography>
            }
            <Box style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
              <Typography data-test-id="cancelUploadText" style={styles.backtosignup} onClick={this.onOpenCancelDialog} >Cancel Upload</Typography>
              <CloseIcon data-test-id="cancelUpload" style={styles.iconClose} onClick={this.onOpenCancelDialog} />
            </Box>
          </Box>
          <Box className={classes?.body}>
            <div className={classes?.leftSide}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={styles.itemContent}>
                <span style={styles.numberFile}>{`${this.state.files.length} Uploaded files`}</span>
                {
                  this.state.files.map((file, index) => {
                    return (
                      <div key={`file_${index}`} style={styles.viewItemFile} onClick={() => this.setState({ currentFile: file })}>
                        {this.renderFilePreview(file)}
                        <span style={styles.viewItemFileText}>{file.name}</span>
                      </div>
                    )
                  })
                }
              </Grid>
            </div>
            <div className={this.props.classes?.middleside}>
              {
                this.state.phase === 1
                  ? (
                    <Typography style={styles.rightText}>
                      Preview:
                      <span style={styles.rightTextName}>{`${this.state.currentFile?.name}`}</span>
                    </Typography>
                  ) : (
                    <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
                      <div>
                        <Tabs
                          value={this.state.currentTab}
                          indicatorColor="primary"
                          textColor="primary"
                          className={this.props.classes?.tab}
                          TabIndicatorProps={{
                            style: { display: 'none' }
                          }}
                        >
                          <MuiTab
                            className={this.state.currentTab === 0 ? this.props.classes?.chosenTab : this.props.classes?.normalTab}
                            label="Original"
                          />
                          <MuiTab
                            className={this.state.currentTab === 1 ? this.props.classes?.chosenTab : this.props.classes?.normalTab}
                            label={"Result"}
                          />
                        </Tabs>
                      </div>
                      <span style={styles.rightTextName}>{`${this.state.currentFile?.name}`}</span>
                    </div>
                  )
              }
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={styles.itemContent}>
                {
                  this.state.phase === 1
                  ? this.renderCurrentFile()
                  : this.handlePhase()
                }
              </Grid>
            </div>
            {this.renderForm()}
          </Box>
          <ConfirmationDialog
            id="ConfirmationDialog"
            isDialogOpen={this.state.isCancel}
            onCloseDialog={this.onCloseCancelDialog}
            title={configJSON.cancelTitle}
            description={configJSON.cancelDescription}
            titleCancelButton={configJSON.backToUpload}
            titleOkButton={configJSON.confirmCancel}
            onOkCloseDialog={this.onCloseAll}
            checkConfirmationTitle=""
          />
        </DialogContent>
      </Dialog>
    );
  }
}

const styles = {
  iconBack: {
    width: "32px",
    height: "32px",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  } as React.CSSProperties,
  inputView: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  } as React.CSSProperties,
  titleText: {
    fontFamily: "DM Sans",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "21px",
    color: "#30353B",
  },
  viewJSON: {
    display: "flex",
    flexDirection: "column"
  } as React.CSSProperties,
  buttonRegenerate: {
    gap: "6px",
    padding: "4px 10px",
    border: "1px solid #684EF3",
    borderRadius: "8px",
    fontFamily: "DM Sans",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#684EF3",
    width: "fit-content",
    display: "flex",
    marginTop: "16px",
    marginBottom: "50px"
  } as React.CSSProperties,
  viewInput: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    padding: "0",
    marginBottom: "20px"
  } as React.CSSProperties,
  titleTextInput: {
    fontFamily: "DM Sans",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "21px",
    color: "#171B21"
  },
  placeholderButtonShowWorkspace: {
    fontFamily: "DM Sans",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#14101E",
  },
  optionItem: {
    color: "#30353B",
    fontFamily: "DM Sans",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "21px",
  },
  iconExpand: {
    width: "24px",
    height: "24px",
  },
  viewDialog: {
    borderRadius: '0px',
    border: '0px solid #D0D2DA',
    height: "100%",
    maxHeight: "100%",
    width: "100%",
    maxWidth: "unset",
    margin: 0,
    backgroundColor: "#fff"
  } as React.CSSProperties,
  mainContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    backgroundColor: "#f6f5f9"
  },
  viewContent: {
    overflow: "auto",
    width: "100%"
  },
  itemContent: {
    paddingRight: "20px",
    cursor: "pointer"
  },
  viewCancel: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    cursor: "pointer",
    width: "fit-object"
  } as React.CSSProperties,
  fileIcon: {
    width: "100%",
    height: "92px"
  },
  headerView: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "24px 40px",
    borderBottom: "1px solid #E2E8F0"
  } as React.CSSProperties,
  numberFile: {
    color: "#14101E",
    fontWeight: 700,
    fontSize: '18px',
    fontFamily: "DM Sans",
    lineHeight: '27px',
    marginBottom: "24px"
  },
  buttonProceed: {
    color: 'rgba(255, 255, 255, 1)',
    fontSize: '16px',
    fontWeight: 400,
    borderRadius: '8px',
    gap: '8px',
    padding: '16px',
    textTransform: 'none'
  } as React.CSSProperties,
  iconClose: {
    marginRight: '36px',
    height: "20px",
    width: "20px",
    padding: "10.39px 10.39px",
    color: "#684EF3"
  },
  viewItemFile: {
    borderRadius: "8px",
    backgroundColor: "#fff",
    padding: "6px",
    gap: "8px",
    display: "flex",
    width: "190px",
    flexDirection: "column"
  } as React.CSSProperties,
  viewItemFileText: {
    color: "#30353B",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "100%",
    whiteSpace: "nowrap",
    fontWeight: 400,
    fontFamily: "DM Sans",
    fontSize: "14px",
    lineHeight: "21px",
  } as React.CSSProperties,
  headerText: {
    padding: "24px 0px 28px 42px",
    fontWeight: 400,
    fontFamily: "DM Sans",
    fontSize: "24px",
    lineHeight: "33.6px",
    color: "#14101E",
    display: "flex",
    gap: "20px"
  } as React.CSSProperties,
  downloadIcon: {
    padding: "0px 0px 28px 42px",
    width: '44px',
    height: '44px',
    gap: '2.8px ',
    cursor: "pointer"
  },

  privacyPolicy: {
    fontWeight: "bold",
    fontFamily: "DM Sans",
    fontSize: "16px",
    color: "#30353B",
    lineHeight: "24px"
  },
  backtosignup: {
    fontWeight: "400",
    fontFamily: "DM Sans",
    fontSize: "16px",
    color: "#684EF3",
    lineHeight: "24px",
    cursor: "pointer"
  },
  heading: {
    fontWeight: "700",
    fontFamily: "DM Sans",
    fontSize: "20px",
    color: "#30353B",
    lineHeight: "30px"
  },
  revisedTime: {
    fontWeight: "400",
    fontFamily: "DM Sans",
    fontSize: "16px",
    color: "#5E6671",
    lineHeight: "24px"
  },
  headerspt: {
    fontWeight: "700",
    fontFamily: "DM Sans",
    fontSize: "16px",
    color: "#30353B",
    lineHeight: "24px"
  },
  parapt: {
    fontWeight: "400",
    fontFamily: "DM Sans",
    fontSize: "16px",
    color: "#30353B",
    lineHeight: "24px"
  },
  uploadText: {
    padding: "10px",
    fontWeight: "700",
    fontFamily: "DM Sans",
    fontSize: "18px",
    color: "#14101E",
    lineHeight: "27px"
  },
  rightText: {
    fontWeight: "400",
    fontFamily: "DM Sans",
    fontSize: "24px",
    color: "#14101E",
    lineHeight: "33.6px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "100%",
    whiteSpace: "nowrap",
    marginBottom: "24px"
  } as React.CSSProperties,
  rightSmallText: {
    fontWeight: "400",
    fontFamily: "DM Sans",
    fontSize: "14px",
    color: "#14101E",
    lineHeight: "21px",
    marginTop: "8px",
    marginBottom: "24px"
  } as React.CSSProperties,
  rightTextName: {
    padding: "10px",
    fontWeight: 400,
    fontFamily: "DM Sans",
    fontSize: "18px",
    color: "#14101E",
    lineHeight: "27px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "100%",
    whiteSpace: "nowrap",
  } as React.CSSProperties,
  dropdowntext: {
    marginTop: "20px",
    fontWeight: "700",
    fontFamily: "DM Sans",
    fontSize: "14px",
    color: "#14101E",
    lineHeight: "21px"
  },
  dialog: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modal: {
    display: 'flex',
    padding: "8px",
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    width: '100%',
    borderBottom: "1px solid #E2E8F0",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: "sticky"
  } as React.CSSProperties,
};

export default DashboardPreviewFile;
// Customizable Area End
