import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import createRequestMessage from "../../../../packages/blocks/utilities/src/create-request-message";
import { baseURL } from "../../../../packages/blocks/bulkuploading/src/BulkUploadingController";
import { getStorageData, removeStorageData, setStorageData } from "../../../../packages/framework/src/Utilities";

// Customizable Area End

export const webConfigJSON = require("./config.js");
 
  // Customizable Area Start
  interface Column {
    id: string;
    label: string;
    align?: 'right' | 'left' | 'center' | 'justify' | 'inherit';
  }
  interface Data {
    [key: string]: any;
  }

  export interface AttachmentObject {
    id: string
    attributes: {
      id: number
      submitted_by: string
      workspace: string
      last_modified: string
      file: FileAttachment
    }
  }

  export interface FileAttachment {
    file_name: string
    url: string
    file_size: number
  }
  // Customizable Area End
export interface Props {
  navigation: any;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  totalCandidates: string;
  type: string;
  token: string;
  fileId:any;
  anchorEl2:any;
  errorMsg: string;
  loading: boolean; 
  columns: Column[];
  rows: Data[];
  isNewAccount:boolean;
  isOpenUploadDialog: boolean;
  isOpenDeleteFiles:boolean;
  fileName:any;
  listAttachment: Array<AttachmentObject>
  searchText: string
  isSnackbarOpen: boolean
  snackbarContent: string
  showProfile: boolean
  isOpenPreviewDialog: boolean
  dataFiles?: { workspace_id: number; files: any; uploadChoice: string }
  previewUrl: string | ArrayBuffer | null,
  showPopup:boolean
  showText:boolean
  closeModal:boolean
  // Customizable Area End
}
interface SS { 
 // Customizable Area Start
  // Customizable Area End
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getListAttachmentId: string = ""
  uploadFileId: string = "";
  removeFilesApiCallId: string = "";
  uploadFileClientServerId: string = ""
  uploadSplitFileClientServerId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      type: "",
      dashboardData: [],
      totalCandidates: "",
      errorMsg: "",
      token: "",
      isOpenDeleteFiles:false,
      fileId:'',
      fileName:'',
      anchorEl2:null,
      columns: [] as Column[],
      rows: [] as Data[],
      loading: true,
      isOpenUploadDialog: false,
      listAttachment: [],
      searchText: "",
      isSnackbarOpen: false,
      snackbarContent: '',
      showProfile: false,
      isNewAccount: false,
      isOpenPreviewDialog: false,
      dataFiles: undefined,
      previewUrl: null,
      showPopup:false,
      showText:false,
      closeModal:false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    const isNewAccount = await getStorageData("new_account")
    this.setState({ isNewAccount: isNewAccount === "true" })
    this.getListAttachments();
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const webHeader = {};
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.dashboardGetUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (webResponseJson && !webResponseJson.errors) {
        if (webApiRequestCallId === this.getListAttachmentId) {
          this.setState({ loading: false, listAttachment: webResponseJson?.data ?? [] });
        }
        if (webApiRequestCallId === this.uploadFileId) {
          if (webResponseJson?.message?.includes("Files have been successfully uploaded")) {
            this.setState({ isSnackbarOpen: true, snackbarContent: "Files have been successfully uploaded" })
            this.getListAttachments()
          } else {
            this.setState({ isSnackbarOpen: true, snackbarContent: "Cannot upload files now" })
          }
        }
        if (webApiRequestCallId == this.removeFilesApiCallId){
          if(webResponseJson.message){
            this.setState({ isOpenDeleteFiles:false,isSnackbarOpen: true, snackbarContent: "Files have been deleted" })
            this.getListAttachments()
          }else{
            this.setState({ isSnackbarOpen: true, snackbarContent: "Cannot deleted files now" })
          }
        }
      } else {
        this.handleError(message)
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start 


  handleClose = () => this.setState({ isSnackbarOpen: false, snackbarContent: "" })

  onCloseAll =()=>{
    this.removeFiles(this.state.fileId)
  }

  onCloseCancelDialog = () => {
    this.setState({ isOpenDeleteFiles: false })
}
confirmdeleteFiles = ()=>{
  this.setState({isOpenDeleteFiles:true, anchorEl2:null})
}
handleClose2 = () => {
  this.setState({anchorEl2:null});
  };
  handleClick1 = (event:any,id:string,name:string) => {
    this.setState({anchorEl2:event.currentTarget, fileId:id, fileName:name});
  };

  handleError = (message: Message) => {
    let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if ((responseJson?.errors?.[0].token === "Token has Expired" || responseJson?.errors?.[0].token === "Invalid token") && apiRequestCallId === this.getListAttachmentId) {
        this.backToHome()
    }
  }

  backToHome = () => {
    removeStorageData("authToken")
    removeStorageData("account_id")
    const msgType: Message = new Message(
        getName(MessageEnum.NavigationMessage)
    );
    msgType.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock"
    );
    msgType.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msgType);
  }

  getListAttachments = async () => {
    this.setState({ loading: true })
    const token = await getStorageData("authToken")
    const request = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    )

    this.getListAttachmentId = request.messageId;
    createRequestMessage({
        token,
        requestMessage: request,
        method: "GET",
        endPoint: `${baseURL}/${webConfigJSON.getAttachment}`
    });
  }
  
  uploadFileAPI = async (data: { workspace_id: number; files: Array<File> }) => {
    const token = await getStorageData("authToken")

    const createBulkUploadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.uploadFileId = createBulkUploadMsg.messageId;

    createBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.createAttachment
    );

    const header = {
      token,
    }

    const formData = new FormData();
    formData.append("workspace_id", data.workspace_id.toString());
    data.files.forEach(item => {
      formData.append( "files[]", item);
    });
    createBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    createBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    createBulkUploadMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");

    runEngine.sendMessage(createBulkUploadMsg.id, createBulkUploadMsg);
  }

  handleAllFiles = async () => {
    await setStorageData('attachments' ,JSON.stringify(this.state.listAttachment))
    this.props.navigation.navigate("DashboardAllFiles")
  }

  openUploadDialog = () => {
    this.setState({ isOpenUploadDialog: true })
  }

  onCloseUploadDialog = (data?: { workspace_id: number; files: Array<File>; uploadChoice: string }) => {
    this.setState({ isOpenUploadDialog: false })
    if (data) {
      if (data.uploadChoice === "Upload file with AI processing") {
        this.setState({ isOpenPreviewDialog: true, dataFiles: data })
      } else {
        this.uploadFileAPI(data)
      }
    }
  }

  

  onChooseFile = (file: AttachmentObject) => {
    const listName = file.attributes.file.file_name.split('.')
    const extension = listName[listName.length - 1]
    this.setState({ isOpenPreviewDialog: true,
      dataFiles: {
        files: [{
          name: file.attributes.file.url,
          type: extension,
          size: file.attributes.file.file_size,
          lastModified: -1,
        }],
        uploadChoice: "",
        workspace_id: 0
      }
    })
  }

  onClosePreviewDialog = (data?: Array<File>) => {
    this.setState({ isOpenPreviewDialog: false })
    if (data) {
      Promise.all(data.map(file => {
        this.uploadFileClientServerAPI(file)
        this.uploadSplitFileClientServerAPI(file)
      }))
    }
  }
  onCloseUserProfileCloseDialog = () => {
    this.setState({ isNewAccount: false })

  }

  removeFiles = async (id:number) => {
    const token = await getStorageData("authToken")
    const header = {
      "Content-Type": webConfigJSON.dashboarContentType, token:token,
    };
    const requestMessage = new Message( getName(MessageEnum.RestAPIRequestMessage) );
    const httpBody = { attachment_id: id};

    this.removeFilesApiCallId = 
    requestMessage.messageId;

    requestMessage.addData(
      getName(
        MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_bulk_uploading/delete_attachment`
    );
    requestMessage.addData(
      getName(
        MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(
        MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(
        MessageEnum.RestAPIRequestMethodMessage),
      'DELETE'
    );

    runEngine.sendMessage(
      requestMessage.id, 
      requestMessage);
  };

  uploadFileClientServerAPI = async (currentFile: File) => {
    const data = new FormData();
    data.append( "file", currentFile);
    fetch(`${webConfigJSON.urlClientServer}/${webConfigJSON.uploadFileClientServer}`, { method: 'POST', body: data })
  }

  uploadSplitFileClientServerAPI = async (currentFile: File) => {
    const data = new FormData();
    data.append( "file", currentFile);
    fetch(`${webConfigJSON.urlClientServer}/${webConfigJSON.uploadSplitFileClientServer}`, { method: 'POST', body: data })
  }

  onChangeSearchInput = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    this.setState({ searchText: event.target.value })
};

toShowDashboardProfile=()=>{
  this.setState({showProfile:!this.state.showProfile})
}
  // Customizable Area End
}
