// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData } from "../../../framework/src/Utilities";
import createRequestMessage from "../../../../packages/blocks/utilities/src/create-request-message";
import { CreateWorkspaceObject, CategoryObject } from "./WorkspaceListController.web";
import { baseURL } from "../../../../packages/blocks/bulkuploading/src/BulkUploadingController";
import { debounce } from "@material-ui/core";
import React from "react";

export const configJSON = require("./config");

export interface UserResponse {
    id: string;
    attributes: {
        activated: boolean
        country_code: string;
        email: string;
        first_name: string | null;
        full_phone_number: string;
        last_name: string | null;
        phone_number: string;
        created_at: string;
        updated_at: string;
        unique_auth_id: string;
        profile_photo: string | null;
        bio: string | null;
        displayed_name: string | null;
        alternate_email: string | null;
        time_zone: string | null;
        working_hours_from: string | null;
        working_hours_to: string | null;
    }
}

export type Props = {
    id: string;
    isDialogOpen: boolean
    onCloseDialog: (data?: CreateWorkspaceObject) => void
    classes?: Record<string, string>
    listCategories: Array<CategoryObject>
}

interface S {
    name: string
    errorName: string
    description: string
    category: string
    listCategory: Array<CategoryObject>
    subCategory: string
    listSubCategory: Array<CategoryObject>
    listUser: Array<UserResponse>
    listChosenUser: Array<UserResponse>
    searchText: string
    selectedUser:any
    userIds:any;
    anchorEl:any;
}

interface SS {
}

class CreateWorkspaceDialogController extends BlockComponent<Props, S, SS> {
    getListUserId: string = ""
    debouncedSearch: any;
    anchorRef:any;
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            name: '',
            errorName: '',
            description: '',
            category: '',
            listCategory: [],
            subCategory: '',
            userIds:"",
            anchorEl:null,
            selectedUser:[],
            listSubCategory: [],
            listUser: [],
            listChosenUser: [],
            searchText: ""
        };
        this.anchorRef = React.createRef();
        this.debouncedSearch = debounce(this.getListUser.bind(this), 300);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson) {
                if (apiRequestCallId === this.getListUserId) {
                    this.setState({ listUser: responseJson?.data ?? [] })
                }
            }
        }
        // Customizable Area End
    }

    handleUserSelect = (user:any) => {
        this.setState((prevState) => ({
          selectedUser: [...prevState.selectedUser, user],
          userIds: [...prevState.userIds, user.id],
          searchText: '',
          listUser:[],
          anchorEl:null,
        }));
      };

      removeUser = (userId:any) => {
        this.setState((prevState) => ({
          selectedUser: prevState.selectedUser.filter((user: { id: any; }) => user.id !== userId),
          userIds: prevState.userIds.filter((id: any) => id !== userId),
        }));
      };
    
    getListUser = async () => {
        const token = await getStorageData("authToken")
        const request = new Message(getName(MessageEnum.RestAPIRequestMessage))

        this.getListUserId = request.messageId;
        createRequestMessage({
            token,
            requestMessage: request,
            method: "GET",
            endPoint: `${baseURL}/${configJSON.searchMemberWorkspace}?term=${this.state.searchText}`
        });
    }

    closeDialog = () => {
        this.setState({
            name: '',
            errorName: '',
            description: '',
            category: '',
            listCategory: this.props.listCategories,
            subCategory: '',
            listSubCategory: [],
            listChosenUser: [],
            selectedUser:[],
            anchorEl:null,
            listUser:[],
            searchText:''
        })
        this.props.onCloseDialog()
    }

    createAndCloseDialog = () => {
        if (this.validationValue()) {
            const data: CreateWorkspaceObject = {
                description: this.state.description,
                name: this.state.name,
                category: this.state.category,
                subcategory: this.state.subCategory,
                account_ids: this.state.userIds
            }
            this.setState({
                name: '',
                errorName: '',
                description: '',
                category: '',
                listCategory: this.props.listCategories,
                subCategory: '',
                listSubCategory: [],
                listChosenUser: [],
                selectedUser:[],
                anchorEl:null,
                listUser:[]
            })
            this.props.onCloseDialog(data)
        }
    }

    onChangeName = (name: string) => {
        this.setState({ name })
        if (name === "") {
            this.setState({ errorName: configJSON.fieldIsNotEmpty })
        }
    }

    onChangeDescription = (description: string) => {
        this.setState({ description })
    }

    onChangeCategory = (value: string) => {
        const currentListCategory = this.state.listCategory.find((item) => item.attributes.name === value)
        const listSubCategory = currentListCategory?.attributes.sub_categories?.map((item) => ({
            id: item.id.toString(),
            attributes: {
                id: item.id,
                name: item.name
            }
        }))
        this.setState({ category: value, subCategory: "" ,listSubCategory: listSubCategory || [] })
    }

    onChangeSubCategory = (value: string) => {
        this.setState({ subCategory: value })
    }

    getCurrentCreateColor = () => {
        if (this.validationValue()) {
            return "#684EF3"
        } else return "#94A3B8"
    }

    validationValue = () => {
        if (this.state.name.trim() === "") {
            return false
        } else if (this.state.category === "") {
            return false
        } else if (this.state.userIds.length === 0) {
            return false
        } else return true
    }

    handleSearchChange(event:any) {
        this.setState({ searchText:event.target.value},()=>this.debouncedSearch(this.state.searchText));
      }

    onRemoveUser = (inputVal: UserResponse) => {
        const newListArray = [...this.state.listUser]
        const newListChosenArray = [...this.state.listChosenUser].filter((item) => item.attributes.email !== inputVal.attributes.email)
        newListArray.push(inputVal)
        this.setState({ searchText: "", listUser: newListArray, listChosenUser: newListChosenArray })
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
        if (prevProps.listCategories.length !== this.props.listCategories.length) {
            this.setState({ listCategory: this.props.listCategories})
        }
    }
}

export default CreateWorkspaceDialogController;
// Customizable Area End
