// Customizable Area Start
import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core";

import ResultTabController, { configJSON } from "./ResultTabController.web";

export class ResultTab extends ResultTabController {

    render() {
        return (
            <div>
                <TableContainer className="resultQueryHub" style={styles.viewTable}>
                    <Table data-test-id="resultTableTest">
                        <TableHead>
                            <TableRow className={this.props.classes?.tableRowHeader} data-test-id="tableTestHeader">
                                <TableCell style={styles.headerText}>
                                    {configJSON.cl_id}
                                </TableCell>
                                <TableCell style={styles.headerText}>
                                    {configJSON.cl_category}
                                </TableCell>
                                <TableCell style={styles.headerText}>
                                    {configJSON.cl_description}
                                </TableCell>
                                <TableCell style={styles.headerText}>
                                    {configJSON.cl_amount}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className={this.props.classes?.tableBody} data-test-id="resultTableRowBodyTest">
                            {this.state.resultList?.map((result, index) => {
                                return (
                                    <TableRow
                                        key={index}
                                        className={this.props.classes?.tableRow}
                                        style={styles.contentText}
                                    >
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            style={styles.contentText}
                                        >
                                            {result.id}
                                        </TableCell>

                                        <TableCell
                                            component="th"
                                            scope="row"
                                            style={styles.contentText}
                                        >
                                            {result.category}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            style={styles.contentText}
                                        >
                                            {result.description}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            style={styles.contentText}
                                        >
                                            {result.amount}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }
}

const styles = {
    container: {
        overflowY: "auto",
        display: "flex",
        width: '100%',
    } as React.CSSProperties,
    headerViewFilterStyle: {
        display: "flex",
        alignItems: "center",
        gap: "12px",
    } as React.CSSProperties,
    headerViewStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    } as React.CSSProperties,
    contentText: {
        color: "#0F172A",
        fontFamily: "DM Sans",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "21px",
        padding: "10px 0px 10px 16px",
        verticalAlign: "middle",
        textAlign: 'left',

    } as React.CSSProperties,
    headerText: {
        fontFamily: "DM Sans",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "21px",
        color: "#475569"
    },
    viewNameTable: {
        display: "flex",
        alignItems: "center"
    } as React.CSSProperties,
    cellIcon: {
        display: "flex",
        alignItems: "flex-end",
        borderBottom: "0px solid #fff"
    } as React.CSSProperties,
    infoCell: {
        padding: "2px 8px",
        color: "#334155",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "21px",
        width: "fit-content",
        backgroundColor: "#F6F5F9",
    } as React.CSSProperties,
    viewTable: {
        backgroundColor: "#fff",
        height: "75%",
    },
    statusCell: {
        padding: "2px 8px",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "21px",
        width: "fit-content",
    } as React.CSSProperties,
};

export default ResultTab;
// Customizable Area End
