import React from "react";

// Customizable Area Start
import {
  Modal,
  Container,
  Box,
  Button,
  Input,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  Checkbox,
  IconButton,
  ListItemText,
  ListItemIcon,
  ListItem,
  List,
  Typography,
  InputAdornment,
  TextField,
  Grid,
  Avatar,
  Chip,
  Menu,
  MenuItem,
  Popper,
  Snackbar,
  SnackbarContent,
  Icon,
  DialogContent,
  Dialog,
} from "@material-ui/core";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import ViewProfileDrawer from "../../user-profile-basic/src/ViewProfileDrawer.web";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import moment from "moment";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { deleteIcon,profileIcon,editIcon,emailRequest, rightArrow, emptyBackground, leaveteamIcon } from "./assets";
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import SearchIcon from '@material-ui/icons/Search';
import UserProfileBasicBlockWeb from "../../../../packages/blocks/user-profile-basic/src/UserProfileBasicBlock.web";


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import AccountGroupsController, {
  configJSON,
  Props,
} from "./AccountGroupsController";

export default class AccountGroups extends AccountGroupsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderteamMemberAvatars = (team:any)=>{
        return(<div style={{ display: 'flex', alignItems: 'center', position: 'absolute', bottom: '16px', left: '126px' }as React.CSSProperties}>
            {team.team_members.slice(0, 7).map((member:any, index:any) => (
              <Avatar key={index} alt={member.name} src={member.avatar} style={{ marginLeft: index > 0 ? '-8px' : '0', height: "32px", width: "32px" }as React.CSSProperties} />
            ))}
            {team.team_members.length > 7 && (
              <Avatar style={{ marginLeft: team.team_members.length > 7 ? '-8px' : '0', height: "32px", width: "32px", backgroundColor: "#D9D1EF", fontSize: "12px", fontFamily: 'DM Sans ', fontWeight: 400, color: "#684EF3" }as React.CSSProperties}>+{team.team_members.length - 7}</Avatar>
            )}
          </div>)
  }
  renderEmptyTeam = () => (
    <>
    <div style={{display:"flex",flexDirection:"row",justifyContent: "space-between",paddingTop:"24px", borderBottom:'1px solid #D6DEEA'}as React.CSSProperties}>
              <Typography variant="h4" gutterBottom style={{ color: 'rgba(20, 16, 30, 1)',fontFamily: 'DM Sans ', fontWeight: 400, fontSize:"24px",paddingLeft:"24px" }as React.CSSProperties}>Teams</Typography>
              <NotificationsNoneIcon style={{width:"24px",height:"24px",paddingRight:"24px"}as React.CSSProperties}/>
    </div>
    <div style={webStyles.emptyWorkspace}>
        <svg style={webStyles.emptyIcon} width="68" height="46" viewBox="0 0 68 46" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path   d="M20.9057 21.8017C18.0312 21.8017 15.5704 20.7782 13.5234 18.7312C11.4764 16.6841 10.4528 14.2234 10.4528 11.3488C10.4528 8.47431 11.4764 6.01353 13.5234 3.96652C15.5704 1.9195 18.0312 0.895996 20.9057 0.895996C21.5154 0.895996 22.1143 0.93955 22.7023 1.02666C23.2902 1.11376 23.8673 1.2662 24.4335 1.48397C23.3011 2.83413 22.4301 4.34761 21.8203 6.02442C21.2106 7.70123 20.9057 9.47604 20.9057 11.3488C20.9057 13.2216 21.2106 14.9964 21.8203 16.6733C22.4301 18.3501 23.3011 19.8636 24.4335 21.2137C23.8673 21.4315 23.2902 21.5839 22.7023 21.671C22.1143 21.7581 21.5154 21.8017 20.9057 21.8017ZM0 42.7074V35.4557C0 33.9749 0.370205 32.603 1.11061 31.3399C1.85102 30.0769 2.87453 29.1187 4.18114 28.4654C5.83617 27.5943 7.63275 26.843 9.57088 26.2115C11.509 25.5799 13.6322 25.09 15.9406 24.7415C14.1984 25.961 12.8483 27.5072 11.8901 29.38C10.9319 31.2528 10.4528 33.278 10.4528 35.4557V42.7074H0ZM36.5849 21.8017C33.7104 21.8017 31.2496 20.7782 29.2026 18.7312C27.1556 16.6841 26.1321 14.2234 26.1321 11.3488C26.1321 8.47431 27.1556 6.01353 29.2026 3.96652C31.2496 1.9195 33.7104 0.895996 36.5849 0.895996C39.4595 0.895996 41.9203 1.9195 43.9673 3.96652C46.0143 6.01353 47.0378 8.47431 47.0378 11.3488C47.0378 14.2234 46.0143 16.6841 43.9673 18.7312C41.9203 20.7782 39.4595 21.8017 36.5849 21.8017ZM15.6793 42.7074V35.4557C15.6793 33.9749 16.0495 32.603 16.7899 31.3399C17.5303 30.0769 18.5538 29.1187 19.8604 28.4654C22.0816 27.333 24.5968 26.3748 27.406 25.5908C30.2152 24.8069 33.2749 24.4149 36.5849 24.4149C37.1076 24.4149 37.6193 24.4258 38.1202 24.4476C38.6211 24.4693 39.111 24.502 39.5901 24.5456C37.7609 27.8556 37.4125 31.2963 38.5449 34.8677C39.6772 38.4391 41.9203 41.0523 45.2739 42.7074H15.6793Z" fill="#D6DEEA"/>
          <path d="M20.9057 21.8017C18.0312 21.8017 15.5704 20.7782 13.5234 18.7312C11.4764 16.6841 10.4528 14.2234 10.4528 11.3488C10.4528 8.47431 11.4764 6.01353 13.5234 3.96652C15.5704 1.9195 18.0312 0.895996 20.9057 0.895996C21.5154 0.895996 22.1143 0.93955 22.7023 1.02666C23.2902 1.11376 23.8673 1.2662 24.4335 1.48397C23.3011 2.83413 22.4301 4.34761 21.8203 6.02442C21.2106 7.70123 20.9057 9.47604 20.9057 11.3488C20.9057 13.2216 21.2106 14.9964 21.8203 16.6733C22.4301 18.3501 23.3011 19.8636 24.4335 21.2137C23.8673 21.4315 23.2902 21.5839 22.7023 21.671C22.1143 21.7581 21.5154 21.8017 20.9057 21.8017ZM0 42.7074V35.4557C0 33.9749 0.370205 32.603 1.11061 31.3399C1.85102 30.0769 2.87453 29.1187 4.18114 28.4654C5.83617 27.5943 7.63275 26.843 9.57088 26.2115C11.509 25.5799 13.6322 25.09 15.9406 24.7415C14.1984 25.961 12.8483 27.5072 11.8901 29.38C10.9319 31.2528 10.4528 33.278 10.4528 35.4557V42.7074H0ZM36.5849 21.8017C33.7104 21.8017 31.2496 20.7782 29.2026 18.7312C27.1556 16.6841 26.1321 14.2234 26.1321 11.3488C26.1321 8.47431 27.1556 6.01353 29.2026 3.96652C31.2496 1.9195 33.7104 0.895996 36.5849 0.895996C39.4595 0.895996 41.9203 1.9195 43.9673 3.96652C46.0143 6.01353 47.0378 8.47431 47.0378 11.3488C47.0378 14.2234 46.0143 16.6841 43.9673 18.7312C41.9203 20.7782 39.4595 21.8017 36.5849 21.8017ZM15.6793 42.7074V35.4557C15.6793 33.9749 16.0495 32.603 16.7899 31.3399C17.5303 30.0769 18.5538 29.1187 19.8604 28.4654C22.0816 27.333 24.5968 26.3748 27.406 25.5908C30.2152 24.8069 33.2749 24.4149 36.5849 24.4149C37.1076 24.4149 37.6193 24.4258 38.1202 24.4476C38.6211 24.4693 39.111 24.502 39.5901 24.5456C37.7609 27.8556 37.4125 31.2963 38.5449 34.8677C39.6772 38.4391 41.9203 41.0523 45.2739 42.7074H15.6793Z" fill="#CDD7E6"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M64.3874 45.104L59.4322 40.2134C58.5291 40.7724 57.5507 41.2025 56.497 41.5036C55.4433 41.8046 54.3574 41.9551 53.2392 41.9551C50.0136 41.9551 47.2719 40.8262 45.014 38.5683C42.7561 36.3104 41.6272 33.5687 41.6272 30.3432C41.6272 27.1176 42.7561 24.3759 45.014 22.118C47.2719 19.8601 50.0136 18.7312 53.2392 18.7312C56.4647 18.7312 59.2064 19.8601 61.4643 22.118C63.7222 24.3759 64.8512 27.1176 64.8512 30.3432C64.8512 31.5044 64.6899 32.6118 64.3673 33.6655C64.0448 34.7192 63.604 35.6976 63.0449 36.6007L68 41.4914L64.3874 45.104ZM53.2374 36.7934C55.0437 36.7934 56.5704 36.1698 57.8177 34.9226C59.0649 33.6754 59.6885 32.1487 59.6885 30.3423C59.6885 28.536 59.0649 27.0093 57.8177 25.7621C56.5704 24.5148 55.0437 23.8912 53.2374 23.8912C51.4311 23.8912 49.9043 24.5148 48.6571 25.7621C47.4099 27.0093 46.7863 28.536 46.7863 30.3423C46.7863 32.1487 47.4099 33.6754 48.6571 34.9226C49.9043 36.1698 51.4311 36.7934 53.2374 36.7934Z" fill="#D6DEEA"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M64.3874 45.104L59.4322 40.2134C58.5291 40.7724 57.5507 41.2025 56.497 41.5036C55.4433 41.8046 54.3574 41.9551 53.2392 41.9551C50.0136 41.9551 47.2719 40.8262 45.014 38.5683C42.7561 36.3104 41.6272 33.5687 41.6272 30.3432C41.6272 27.1176 42.7561 24.3759 45.014 22.118C47.2719 19.8601 50.0136 18.7312 53.2392 18.7312C56.4647 18.7312 59.2064 19.8601 61.4643 22.118C63.7222 24.3759 64.8512 27.1176 64.8512 30.3432C64.8512 31.5044 64.6899 32.6118 64.3673 33.6655C64.0448 34.7192 63.604 35.6976 63.0449 36.6007L68 41.4914L64.3874 45.104ZM53.2374 36.7934C55.0437 36.7934 56.5704 36.1698 57.8177 34.9226C59.0649 33.6754 59.6885 32.1487 59.6885 30.3423C59.6885 28.536 59.0649 27.0093 57.8177 25.7621C56.5704 24.5148 55.0437 23.8912 53.2374 23.8912C51.4311 23.8912 49.9043 24.5148 48.6571 25.7621C47.4099 27.0093 46.7863 28.536 46.7863 30.3423C46.7863 32.1487 47.4099 33.6754 48.6571 34.9226C49.9043 36.1698 51.4311 36.7934 53.2374 36.7934Z" fill="#CDD7E6"/>
        </svg>

        <span style={webStyles.emptyText}>No created teams yet</span>
        <div data-test-id="createTeamnewUser" style={webStyles.buttonCreate} onClick={this.handleCreateTeamClick}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 11H13V5H11V11H5V13H11V19H13V13H19V11Z" fill="white"/>
            </svg>
            <span style={webStyles.createEmptyText}>Create new team</span>
        </div>
    </div>
    </>
)
rendorTeamMenu = () => {
  const { isAdmin, anchorEl2, memberID } = this.state;

  return (
    <Menu
      anchorEl={anchorEl2}
      open={Boolean(anchorEl2)}
      onClose={this.handleClose2}
      PaperProps={{
        elevation: 0,
        style: {
          boxShadow: 'none',
          border: "1px solid #E2E8F0"
        }
      }}
    >
      {isAdmin ? (
        <>
          <MenuItem onClick={this.handleEditTeam}>
            <ListItemIcon>
              <img
                src={editIcon}
                alt="Edit team name"
                style={{ height: "16px", width: "18px" } as React.CSSProperties}
              />
            </ListItemIcon>
            <ListItemText
              primary="Edit team name"
              style={{ fontFamily: 'DM Sans', fontWeight: 400, fontSize: "14px" } as React.CSSProperties}
            />
          </MenuItem>
          <MenuItem onClick={this.deleteTeam}>
            <ListItemIcon>
              <img
                src={deleteIcon}
                alt="Delete team"
                style={{ height: "20px", width: "18px" } as React.CSSProperties}
              />
            </ListItemIcon>
            <ListItemText
              primary="Delete team"
              style={{ color: "#DC2626", fontFamily: 'DM Sans', fontWeight: 400, fontSize: "14px" } as React.CSSProperties}
            />
          </MenuItem>
        </>
      ) : (
        <MenuItem data-test-id="leaveTeam" onClick={() => this.removeMemberFromTeam(memberID)}>
          <ListItemIcon>
            <img
              src={leaveteamIcon}
              alt="Leave team"
              style={{ height: "16px", width: "18px", color: "#DC2626" } as React.CSSProperties}
            />
          </ListItemIcon>
          <ListItemText
            primary="Leave team"
            style={{ color: "#DC2626", fontFamily: 'DM Sans', fontWeight: 400, fontSize: "14px" } as React.CSSProperties}
          />
        </MenuItem>
      )}
    </Menu>
  );
}

renderTableIcons = (member: any) => {
  if (member?.permission === "Admin") {
    return null; // Don't render any icon for Admin members
  } else {
    if (!this.state.isAdmin) {
      return (
        <IconButton data-test-id={`member-${member.id}`} onClick={()=>this.handleViewProfileClose(member.account_id)}>
          <img
            src={profileIcon}
            alt="View profile"
            style={{ height: "16px", width: "18px" }}
          />
        </IconButton>
      );
    } else {
      return (
        <IconButton
          data-test-id={`member-${member.id}`}
          onClick={(event) => this.handleClick(event, member.id)}
        >
          <MoreVertIcon />
        </IconButton>
      );
    }
  }
};



renderAddMemberButton = () => {
  const { isAdmin } = this.state;

  if (!isAdmin) return null;

  return (
    <Button
      data-test-id="btnGetGroups"
      variant="contained"
      color="primary"
      fullWidth
      style={{
        color: 'rgba(255, 255, 255, 1)',
        backgroundColor: '#684EF3',
        fontSize: '16px',
        fontWeight: 400,
        borderRadius: '8px',
        gap: '8px',
        padding: '16px',
        textTransform: 'none',
        width: "191px",
        height: "44px",
        paddingRight: "24px",
        textAlign: "center"
      } as React.CSSProperties}
      onClick={this.showHideAddAccountModal}
    >
      <span style={{ fontWeight: 400, fontSize: '24px', paddingRight: "5px" }}>+ </span>
      Add member
    </Button>
  );
}

 getChipColor = (status:any) => {
    switch (status) {
      case 'Accepted':
        return '#D1FAE5';
      case 'Pending':
        return '#FEF3C7';
      case 'Declined':
        return '#FEE2E2';
      default:
        return 'default';
    }
  };
  getFontColor = (status:any) => {
    switch (status) {
      case 'Accepted':
        return '#059669';
      case 'Pending':
        return '#D97706';
      case 'Declined':
        return '#DC2626';
      default:
        return 'default';
    }
  };

  // Customizable Area End

  render() {
    // Customizable Area Start    
    return (
      <div style={{display:"flex",flexDirection:"row",backgroundColor:"#f6f5f9", height:"100%",width:"100%"}as React.CSSProperties}>
        <div style={{position:"sticky"}as React.CSSProperties}>
          <NavigationMenu navigation={this.props.navigation} id="accountgroups" showProfileFunc={this.toTeamShowProfile}/>
        </div>
      {this.state.showProfile ? (<UserProfileBasicBlockWeb navigation={undefined} id="" />):(
      <div style={{overflowY:"auto", width:"100%"}as React.CSSProperties}>
      {this.state.isTeamEmpty ? (<>
           {!this.state.isTeamsClicked ? (<><div style={{display:"flex",flexDirection:"row",justifyContent: "space-between",paddingTop:"24px", borderBottom:'1px solid #D6DEEA'}as React.CSSProperties}>
              <Typography variant="h4" gutterBottom style={{ color: 'rgba(20, 16, 30, 1)',fontFamily: 'DM Sans ', fontWeight: 400, fontSize:"24px",paddingLeft:"24px" }as React.CSSProperties}>Teams</Typography>
              <NotificationsNoneIcon style={{width:"24px",height:"24px",paddingRight:"24px"}as React.CSSProperties}/>
            </div>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "20px 24px 20px 24px"}as React.CSSProperties}>
            <TextField
    style={{
      borderRadius: '8px',
      backgroundColor: 'rgba(255, 255, 255, 1)',
      width: "288px",
      marginRight: '16px',
      height: "44px"
    }as React.CSSProperties}
    name='search'
    placeholder="Search"
    data-test-id="Search1"
    type='text'
    value={this.state.searchTeamName}
    onChange={this.handleSearchTeamNameChange}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon style={{paddingLeft:"10px", color:"#94A3B8"}as React.CSSProperties}/>
        </InputAdornment>
      ),
      disableUnderline: true,
      style: {
        height: "100%",
        padding: 0,
        borderRadius: '8px',
        width: "288px",
        border:"1px solid #E2E8F0"
      }
    }}
  />
  <Button
    data-test-id="btnAddGroupModal"
    variant="contained"
    color="primary"
    fullWidth
    style={{ color: 'rgba(255, 255, 255, 1)', backgroundColor: '#684EF3', fontSize: '16px', fontWeight: 400, borderRadius: '8px', gap: '8px', padding: '16px', textTransform: 'none',width:"191px",height:"44px",paddingRight:"24px",textAlign:"center" }as React.CSSProperties}
    onClick={this.handleCreateTeamClick}
  >
    <span style={{fontWeight:400,fontSize:'24px',paddingRight:"5px"}}>{"+ "}</span>  Create new team
  </Button>
</div>

<Grid container spacing={2} style={{padding: "20px 0px 10px 24px",overflow:"auto",width:"100%"}as React.CSSProperties}>
    {this.state.teams.map((team:any, index:any) => (
      <Grid  container item xl={4} lg={4} md={6} sm={6} xs={12} 
      style={{paddingRight:"20px"}} key={index}>
        <Paper data-test-id={`Team-${index}`}  style={{ padding: '16px', position: 'relative',width:"100%",height:"150px",borderRadius:"16px" }as React.CSSProperties} onClick={()=>this.handleViewTeamClick(team.team_id)}>
          <Typography variant="h6" style={{ marginBottom: '8px' ,color: 'rgba(20, 16, 30, 1)',fontFamily: 'DM Sans ', fontWeight: 700, fontSize:"18px"}as React.CSSProperties}>
            {team.team_name}
          </Typography>
          <hr style={{ border: 'none', borderTop: '1px solid #D6DEEA', marginTop: '75px' }as React.CSSProperties} />
          <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', bottom: '16px', left: '36px',flexDirection:"row" }as React.CSSProperties}>
            <SupervisorAccountIcon style={{ color: "#94A3B8" }as React.CSSProperties} />
            <Typography variant="body2" style={{ marginLeft: '4px', color: 'rgba(20, 16, 30, 1)', fontFamily: 'DM Sans ', fontWeight: 500, fontSize: "16px" }as React.CSSProperties}>
              {team.team_members_count}
            </Typography>
          </div>
          {this.renderteamMemberAvatars(team)}
          <div style={{ position: 'absolute', bottom: '16px', left: "90px", height: '32px', borderLeft: '1px solid #D6DEEA' }as React.CSSProperties}></div>
        </Paper>
      </Grid>
    ))}
  </Grid></>):(<> <div style={{display:"flex",flexDirection:"row",justifyContent: "space-between",paddingTop:"24px", borderBottom:'1px solid #D6DEEA'}as React.CSSProperties}>
  <div style={{display:"flex",flexDirection:"row",justifyContent: "space-between",alignItems:"center",paddingBottom:"10px"}as React.CSSProperties}>
              <Typography data-test-id="gototeam" variant="h4" gutterBottom style={{ color: '#5E6671',fontFamily: 'DM Sans ', fontWeight: 400, fontSize:"14px",paddingLeft:"24px", cursor:"pointer",lineHeight:"24px" }as React.CSSProperties} onClick={this.handleTeamClick}>Teams</Typography>
              <img src={rightArrow} style={{height:"9.51px",width:"5.93px",paddingLeft:"10px",paddingRight:"10px"}as React.CSSProperties}/>
              <Typography variant="h4" gutterBottom style={{ color: 'rgba(20, 16, 30, 1)',fontFamily: 'DM Sans ', fontWeight: 400, fontSize:"14px",paddingLeft:"4px" ,lineHeight:"24px"}as React.CSSProperties}> {this.state?.individualTeam?.team_name}</Typography>
              </div>
              <NotificationsNoneIcon style={{width:"24px",height:"24px",paddingRight:"24px"}as React.CSSProperties}/>
            </div>
            <div 
  style={{ 
    display: "flex", 
    alignItems: "center",  
    padding: "24px" 
  } as React.CSSProperties}
>
  <Typography 
    variant="h4" 
    gutterBottom 
    style={{ 
      color: 'rgba(20, 16, 30, 1)', 
      fontFamily: 'DM Sans ', 
      fontWeight: 400, 
      fontSize: "24px", 
      margin: "0 14px 0 0", 
      lineHeight: "1.2"     
    } as React.CSSProperties}
  >
    {this.state?.individualTeam?.team_name}
  </Typography>
  <IconButton 
    style={{ 
      margin: "0", 
      padding: "0" 
    } as React.CSSProperties} 
    onClick={this.handleClick2}
  >
    <MoreVertIcon />
  </IconButton>
  {this.rendorTeamMenu()}
</div>



            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between",paddingLeft:"24px",paddingRight:"24px"}as React.CSSProperties}>
            <TextField
    style={{
      borderRadius: '8px',
      backgroundColor: 'rgba(255, 255, 255, 1)',
      width: "288px",
      marginRight: '16px',
      height: "44px"
    }as React.CSSProperties}
    name='search'
    placeholder="Search"
    data-test-id="Search2"
    type='text'
    value={this.state.searchMember}
    onChange={this.handleSearchMemberChange}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon style={{paddingLeft:"10px",color:'#94A3B8'}as React.CSSProperties}/>
        </InputAdornment>
      ),
      disableUnderline: true,
      style: {
        height: "100%",
        padding: 0,
        borderRadius: '8px',
        border:"1px solid #E2E8F0",
        width: "288px"
      }as React.CSSProperties
    }}
  />
  {this.renderAddMemberButton()}
 
</div>
<div style={{padding:"24px"}}>
<Paper style={webStyles.tableViewStyle}>
            <TableContainer style={webStyles.tableContainerStyle}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>{this.state?.teamMembers?.length} Members</TableCell>
                    <TableCell>Adding date</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Permission</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {this.state.teamMembers.map((member:any) => (
  <TableRow key={member.id}>
    <TableCell>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar alt={`${member.first_name} ${member.last_name}`} src={member.avatar} style={{ marginRight: 8 }} />
        {`${member.first_name || ''} ${member.last_name || ''}`}
      </div>
    </TableCell>
    <TableCell>{moment(member.adding_date).format("MMM DD, YYYY")}</TableCell>
    <TableCell>{member.email}</TableCell>
    <TableCell>
      <Chip 
        label={member.status || 'N/A'} 
        style={{ 
          backgroundColor: this.getChipColor(member.status), 
          color: this.getFontColor(member.status),
          textTransform: "uppercase" 
        }}
      />
    </TableCell>
    <TableCell>
    <Chip
  label={
    <span style={{ display: 'flex', alignItems: 'center' }}>
      {member.permission || 'N/A'}
      {!this.state.isAdmin ? (null):(
      <Icon style={{ marginLeft: 8 }}>
        <ExpandMoreIcon />
      </Icon>)}
    </span>
  }
  style={{ backgroundColor: "#F1F5F9", color: "#30353B" }}
/>
    </TableCell>
    <TableCell align="right">
    {this.renderTableIcons(member)}
      <Menu
        anchorEl={this.state.anchorEl}
        open={Boolean(this.state.anchorEl)}
        onClose={this.handleClose}
        PaperProps={{
          elevation: 0,
          style: { boxShadow: 'none', border: "1px solid #E2E8F0" },
        }}
      >
        <MenuItem data-test-id={`resendmember-${member.id}`} onClick={()=>this.resendaRequest(this.state.selectedmemberID)}>
          <ListItemIcon>
            <img src={emailRequest} alt="Resend a request" style={{height:"16px",width:"22px"}}/>
          </ListItemIcon>
          <ListItemText primary="Resend a request" style={{ fontFamily: 'DM Sans', fontWeight: 400, fontSize: "14px" }} />
        </MenuItem>
        <MenuItem onClick={()=>this.handleViewProfileClose(member.account_id)}>
          <ListItemIcon>
            <img src={profileIcon} alt="View profile" style={{height:"16px",width:"18px"}}/>
          </ListItemIcon>
          <ListItemText primary="View profile" style={{ fontFamily: 'DM Sans', fontWeight: 400, fontSize: "14px" }} />
        </MenuItem>
        <MenuItem data-test-id={`removemember-${member.id}`} onClick={()=>this.removeMemberFromTeam(this.state.selectedmemberID)}>
          <ListItemIcon>
            <img src={deleteIcon} alt="Remove member" style={{height:"20px",width:"18px"}}/>
          </ListItemIcon>
          <ListItemText primary="Remove member" style={{ color: "#DC2626", fontFamily: 'DM Sans', fontWeight: 400, fontSize: "14px" }} />
        </MenuItem>
      </Menu>
    </TableCell>
  </TableRow>
))}

                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          </div>
</> )}</>):(this.renderEmptyTeam())}

          <Modal
            open={this.state.isCreateTeamClicked}
            onClose={this.hideAddAccountModal}
          >
            <Box sx={webStyles.modalStyle}>
              <div style={{borderBottom:"1px solid #D6DEEA", width:"100%"}as React.CSSProperties}>
              <Typography variant="h6" style={{ marginBottom: '8px' ,color: 'rgba(20, 16, 30, 1)',fontFamily: 'DM Sans', fontWeight: 400, fontSize:"24px",padding:'20px',paddingLeft:"40px"}as React.CSSProperties}>
           Create new team
          </Typography>
          </div>
          <Box style={{ width: '85%',paddingLeft:"40px",paddingRight:"40px" }as React.CSSProperties}>
  <Typography style={{ marginTop:'32px', fontFamily: 'DM Sans', fontWeight: 700, fontSize: '14px', color: 'rgba(48, 53, 59, 1)' }as React.CSSProperties}>Team name</Typography>
  <TextField
    style={{
      borderRadius: '8px',
      border: '1px solid #E2E8F0',
      backgroundColor: 'rgba(255, 255, 255, 1)',  
    }as React.CSSProperties}
    name='name'
    placeholder="Enter team name"
    data-test-id="inputName"
    variant="outlined" 
    fullWidth 
    required
    value={this.state.teamName}
    error={this.state.teamNameError}
    onChange={this.handleChange}
  />
</Box>
{this.state.teamNameError&&<Box sx={{ marginTop:"5px",color: 'red', fontSize: '12px', fontFamily: 'DM Sans', fontWeight: 400,marginLeft:"7%" }}>
          
          <Typography style={{ fontFamily: 'DM Sans', fontSize: '12px'}}>Team name is required</Typography>
        </Box>}
<Box style={{ width: '85%', paddingLeft: "40px", paddingRight: "40px" }as React.CSSProperties}>
            <Typography
              style={{
                marginTop: '32px',
                fontFamily: 'DM Sans ',
                fontWeight: 700,
                fontSize: '14px',
                color: 'rgba(48, 53, 59, 1)'
              }as React.CSSProperties}
            >
              Add members
            </Typography>
            <TextField
              style={{
                borderRadius: '8px',
                backgroundColor: 'rgba(255, 255, 255, 1)',
                marginRight: '16px',
                width: "100%",
              }as React.CSSProperties}
              name='search'
              placeholder="Search member to add by email or name"
              data-test-id="inputName2"
              type='text'
              variant="outlined"
              value={this.state.searchValue}
              onChange={(e)=>this.handleSearchChange(e)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ paddingLeft: "5px", color: "#94A3B8" }as React.CSSProperties} />
                  </InputAdornment>
                ),
                style: {
                  height: "100%",
                  padding: 0,
                  border: 'none',
                },
              }}
              inputRef={this.anchorRef}
            />
            <Popper
              open={this.state.searchResults?.length > 0}
              anchorEl={this.anchorRef.current}
              style={{ zIndex: 99999 }as React.CSSProperties}
              placement="bottom-start"
            >
              <Box
                style={{
                  backgroundColor: '#fff',
                  maxHeight: "200px",
                  overflowY: 'auto',
                  borderRadius: '4px',
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                  width: this.anchorRef.current ? this.anchorRef.current.offsetWidth : 'auto',
                  border: '1px solid #D6DEEA',
                  padding: '8px 0',
                }as React.CSSProperties}
              >
                {this.state?.searchResults?.map((result:any, index:any) => (
                  <MenuItem
                    key={index}
                    onClick={() => this.handleUserSelect(result)}
                    style={{
                      padding: '8px 16px',
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      fontFamily: 'DM Sans ',
                      fontSize: '14px',
                      color: 'rgba(48, 53, 59, 1)',
                    }as React.CSSProperties}
                  >
                    <Typography variant="body2" color="textPrimary">
                      {result.attributes.email}
                    </Typography>
                  </MenuItem>
                ))}
              </Box>
            </Popper>
          </Box>
          <Box
            style={{
              marginTop: '16px',
              maxHeight: '120px',
              overflowY: 'auto',
              width: '85%',
              paddingLeft: "40px",
              paddingRight: "20px"
            }as React.CSSProperties}
          >
            <Grid container spacing={2}>
              {this.state.users.map((user:any, index:any) => (
                <Grid item xs={12} key={index}>
                  <Paper
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '16px',
                      backgroundColor: "#F6F5F9"
                    }as React.CSSProperties}
                  >
                    <Avatar alt={user.attributes.name} src={user.attributes.avatar} />
                    <Box style={{ marginLeft: '16px', flexGrow: 1 }as React.CSSProperties}>
                      <Typography
                        style={{
                          fontFamily: 'DM Sans',
                          fontWeight: 700,
                          fontSize: "14px"
                        }as React.CSSProperties}
                      >
                        {user.attributes.first_name} {user.attributes.last_name}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'DM Sans',
                          color: 'rgba(48, 53, 59, 1)',
                          fontWeight: 400,
                          fontSize: "14px"
                        }as React.CSSProperties}
                      >
                        {user.attributes.email}
                      </Typography>
                    </Box>
                    <Button
                      data-test-id="btnCloseGroup"
                      variant="contained"
                      style={{
                        color: '#000',
                        borderColor: '#fff',
                        backgroundColor: "#fff",
                        fontSize: '14px',
                        fontWeight: 400,
                        borderRadius: '8px',
                        gap: '8px',
                        padding: '16px',
                        textTransform: 'none',
                        width: "133px",
                        height: "24px",
                        fontFamily: 'DM Sans '
                      }as React.CSSProperties}
                      onClick={()=>this.removeUser(user.id)}
                    >
                      X Remove
                    </Button>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>

              <Box sx={webStyles.modalButtonViewStyle}>
                
                <Button
                  data-test-id="btnHideModal1"
                  variant="outlined"
                  style={{ color: '#684EF3', borderColor: '#684EF3', fontSize: '16px', fontWeight: 400, borderRadius: '8px', gap: '8px', padding: '16px',textTransform: 'none',width:"104px", height:"44px"  }as React.CSSProperties}
                  onClick={this.hideAddAccountModal}
                >
                  {configJSON.textCancel}
                </Button>
                <Button
                  data-test-id="btnAddGroup2"
                  variant="contained"
                  color="primary"
                  style={{ color: 'rgba(255, 255, 255, 1)',  backgroundColor:'#684EF3', fontSize: '16px', fontWeight: 400, borderRadius: '8px', gap: '8px', padding: '16px' ,textTransform: 'none',height:"44px",width:"395px",left:"20px" }as React.CSSProperties}
                  onClick={this.handleCreateTeam}
                >
                  {configJSON.textCreate}
                </Button>
              </Box>
            </Box>
          </Modal>

          <Modal
            open={this.state.isEditTeamClicked}
            onClose={this.handleCloseEditTeam}
          >
            <Box sx={webStyles.modalStyle}>
              <div style={{borderBottom:"1px solid #D6DEEA", width:"100%"}as React.CSSProperties}>
              <Typography variant="h6" style={{ marginBottom: '8px' ,color: 'rgba(20, 16, 30, 1)',fontFamily: 'DM Sans', fontWeight: 400, fontSize:"24px",padding:'20px',paddingLeft:"40px"}as React.CSSProperties}>
           Edit team name
          </Typography>
          </div>
          <Box style={{ width: '85%',paddingLeft:"40px",paddingRight:"40px" }as React.CSSProperties}>
  <Typography style={{ marginTop:'32px', fontFamily: 'DM Sans ', fontWeight: 700, fontSize: '14px', color: 'rgba(48, 53, 59, 1)' }as React.CSSProperties}>Team name</Typography>
  <TextField
    style={{
      borderRadius: '8px',
      border: '1px solid #E2E8F0',
      backgroundColor: 'rgba(255, 255, 255, 1)',  
    }as React.CSSProperties}
    name='name'
    placeholder="Enter team name"
    data-test-id="inputName3"
    variant="outlined" 
    fullWidth 
    required
    value={this.state.teamName}
    error={this.state.teamNameError}
    onChange={this.handleChange}
  />
</Box>

              <Box sx={webStyles.modalButtonViewStyle}>
                
                <Button
                  data-test-id="btnHideModal"
                  variant="outlined"
                  style={{ color: '#684EF3', borderColor: '#684EF3', fontSize: '16px', fontWeight: 400, borderRadius: '8px', gap: '8px', padding: '16px',textTransform: 'none',width:"104px", height:"44px"  }as React.CSSProperties}
                  onClick={this.handleCloseEditTeam}
                >
                  {configJSON.textCancel}
                </Button>
                <Button
                  data-test-id="btnAddGroup"
                  variant="contained"
                  color="primary"
                  style={{ color: 'rgba(255, 255, 255, 1)',  backgroundColor:'#684EF3', fontSize: '16px', fontWeight: 400, borderRadius: '8px', gap: '8px', padding: '16px' ,textTransform: 'none',height:"44px",width:"395px",left:"20px" }as React.CSSProperties}
                  onClick={this.handleUpdateTeamname}
                >
                  {configJSON.textUpdate}
                </Button>
              </Box>
            </Box>
          </Modal>
          <Modal
        open={this.state.isVisibleAddAccountModal}
        onClose={this.showHideAddAccountModal}
      >
        <Box sx={webStyles.modalStyle}>
          <div style={{ borderBottom: "1px solid #D6DEEA", width: "100%" }}>
            <Typography
              variant="h6"
              style={{
                marginBottom: '8px',
                color: 'rgba(20, 16, 30, 1)',
                fontFamily: 'DM Sans ',
                fontWeight: 400,
                fontSize: "24px",
                padding: '20px',
                paddingLeft: "40px"
              }}
            >
              Add member to {this.state.teamMembers.team_name}
            </Typography>
          </div>
          <Box style={{ width: '85%', paddingLeft: "40px", paddingRight: "40px" }}>
            <Typography
              style={{
                marginTop: '32px',
                fontFamily: 'DM Sans ',
                fontWeight: 700,
                fontSize: '14px',
                color: 'rgba(48, 53, 59, 1)'
              }}
            >
              Search members
            </Typography>
            <TextField
              style={{
                borderRadius: '8px',
                backgroundColor: 'rgba(255, 255, 255, 1)',
                marginRight: '16px',
                width: "100%",
              }}
              name='search'
              placeholder="Search member to add by email or name"
              data-test-id="Search3"
              type='text'
              variant="outlined"
              value={this.state.searchValue}
              onChange={(e)=>this.handleSearchChange(e)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ paddingLeft: "5px", color: "#94A3B8" }} />
                  </InputAdornment>
                ),
                style: {
                  height: "100%",
                  padding: 0,
                  border: 'none',
                },
              }}
              inputRef={this.anchorRef}
            />
            <Popper
              open={this.state.searchResults?.length > 0}
              anchorEl={this.anchorRef.current}
              style={{ zIndex: 9999 }}
              placement="bottom-start"
            >
              <Box
                style={{
                  backgroundColor: '#fff',
                  maxHeight: "200px",
                  overflowY: 'auto',
                  borderRadius: '4px',
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                  width: this.anchorRef.current ? this.anchorRef.current.offsetWidth : 'auto',
                  border: '1px solid #D6DEEA',
                  padding: '8px 0',
                }}
              >
                {this.state.searchResults?.map((result:any, index:any) => (
                  <MenuItem
                    key={index}
                    onClick={() => this.handleUserSelect(result)}
                    style={{
                      padding: '8px 16px',
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      fontFamily: 'DM Sans ',
                      fontSize: '14px',
                      color: 'rgba(48, 53, 59, 1)',
                    }}
                  >
                    <Typography variant="body2" color="textPrimary">
                      {result.attributes.email}
                    </Typography>
                  </MenuItem>
                ))}
              </Box>
            </Popper>
          </Box>
          <Box
            style={{
              marginTop: '16px',
              maxHeight: '120px',
              overflowY: 'auto',
              width: '85%',
              paddingLeft: "40px",
              paddingRight: "20px"
            }}
          >
            <Grid container spacing={2}>
              {this.state.users.map((user:any, index:any) => (
                <Grid item xs={12} key={index}>
                  <Paper
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '16px',
                      backgroundColor: "#F6F5F9"
                    }}
                  >
                    <Avatar alt={user.attributes.name} src={user.attributes.avatar} />
                    <Box style={{ marginLeft: '16px', flexGrow: 1 }}>
                      <Typography
                        style={{
                          fontFamily: 'DM Sans ',
                          fontWeight: 700,
                          fontSize: "14px"
                        }}
                      >
                        {user.attributes.first_name} {user.attributes.last_name}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'DM Sans ',
                          color: 'rgba(48, 53, 59, 1)',
                          fontWeight: 400,
                          fontSize: "14px"
                        }}
                      >
                        {user.attributes.email}
                      </Typography>
                    </Box>
                    <Button
                      data-test-id="btnCloseAddAccountModal"
                      variant="contained"
                      style={{
                        color: '#000',
                        borderColor: '#fff',
                        backgroundColor: "#fff",
                        fontSize: '14px',
                        fontWeight: 400,
                        borderRadius: '8px',
                        gap: '8px',
                        padding: '16px',
                        textTransform: 'none',
                        width: "133px",
                        height: "24px",
                        fontFamily: 'DM Sans '
                      }}
                      onClick={()=>this.removeUser(user.id)}
                    >
                      X Remove
                    </Button>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box sx={webStyles.modalButtonViewStyle}>
            <Button
              data-test-id="btnCloseAddAccountModal1"
              variant="outlined"
              style={{
                color: '#684EF3',
                borderColor: '#684EF3',
                fontSize: '16px',
                fontWeight: 400,
                borderRadius: '8px',
                gap: '8px',
                padding: '16px',
                textTransform: 'none',
                width: "104px",
                height: "44px"
              }}
              onClick={this.showHideAddAccountModal}
            >
              {configJSON.textCancel}
            </Button>
            <Button
              data-test-id="btnSaveAccountsToGroup"
              variant="contained"
              color="primary"
              style={{
                color: 'rgba(255, 255, 255, 1)',
                backgroundColor: '#684EF3',
                fontSize: '16px',
                fontWeight: 400,
                borderRadius: '8px',
                gap: '8px',
                padding: '16px',
                textTransform: 'none',
                height: "44px",
                width: "395px",
                left: "20px"
              }}
              onClick={this.handleAddmember}
            >
              {configJSON.textAdd}
            </Button>
          </Box>
        </Box>
       
      </Modal>
      <Snackbar
          anchorOrigin={{ vertical:"top", horizontal:"center" }}
          open={this.state.isSuccess}
          onClose={this.handleSuccessClose}
          key={"top" + "center"}
        >
          <SnackbarContent
            message={this.state.isSuccessMessage}
            style={{ backgroundColor: '#33BC55',borderRadius:"8px" }}
            action={
              <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleSuccessClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical:"top", horizontal:"center" }}
          open={this.state.isError}
          onClose={this.handleErrorClose}
          key={"top" + "center"}
        >
          <SnackbarContent
            message={this.state.isErrorMessage}
            style={{ backgroundColor: '#DC2626',borderRadius:"8px" }}
            action={
              <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleErrorClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />
        </Snackbar>
      <Dialog
                aria-labelledby="customized-dialog-title"
                open={this.state.isAcceptTeam}
                fullWidth
                PaperProps={{
                    style: webStyles.viewDialog
                }}
            >
                <DialogContent
                    className="dialog-address-content"
                    style={{ padding: 0 }}
                >
                    <div style={webStyles.textTitle}>{configJSON.teamInviteText}</div>
                    <div style={webStyles.textDescription} dangerouslySetInnerHTML={{ __html: configJSON.teamInviteDescription }}/>
                    <div style={webStyles.viewButton}>
                        <div
                            data-test-id="cancelButton"
                            onClick={this.onOkCloseDialog}
                            style={webStyles.cancelButton}
                        >
                            Accept
                        </div>
                        <div
                            data-test-id="okButton"
                            onClick={this.onCloseRemoveDialog}
                            style={webStyles.okButton}
                        >
                            Decline
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
          
      {this.state.isOpenViewDialog &&
            <ViewProfileDrawer
              id="ViewProfileDrawer"
              isDialogOpen={this.state.isOpenViewDialog}
              onCloseDialog={this.onCloseViewDialog}
              classes={this.props?.classes}
              account_id={this.state.accountId}
          />}
      </div>)}

      </div> 
      
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  viewDialog: {
    borderRadius: '24px',
    border: '0px solid #D0D2DA',
    maxHeight: "50%",
    backgroundColor: "#fff"
},
textTitle: {
    color: "#14101E",
    fontFamily: "DM Sans",
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "33.6px",
    padding: "24px 32px",
    borderBottom: '1px solid #F6F5F9',
    backgroundColor: "#fff"
} as React.CSSProperties,
textDescription: {
    color: "#14101E",
    fontFamily: "DM Sans",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    padding: "0 32px",
    marginTop: "24px"
},
viewButton: {
    display: "flex",
    gap: "16px",
    marginTop: "24px",
    marginBottom: "32px",
    padding: "0 32px"
},
cancelButton: {
    borderRadius: "8px",
    color: "#684EF3",
    fontFamily: "DM Sans",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    paddingTop: "10px",
    paddingBottom: "10px",
    backgroundColor: "#fff",
    border: "1px solid #684EF3",
    width: "100%",
    textAlign: "center",
    cursor: "pointer"
} as React.CSSProperties,
okButton: {
    borderRadius: "8px",
    color: "#ff0000",
    fontFamily: "DM Sans",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    paddingTop: "10px",
    paddingBottom: "10px",
    backgroundColor: "#FEE2E2",
    width: "100%",
    textAlign: "center",
    cursor: "pointer"
} as React.CSSProperties,
  modalStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    position: "absolute",
    top: "50%",
    left: "50%",
    borderRadius:"24px",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
  },
  modalRowViewStyle: {
    display: "flex",
  },
  dropdownViewStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: 280,
    justifyContent: "space-between",
    padding: 10,
    border: "1px solid #ccc",
    borderRadius: 5,
  },createEmptyText: {
    color: "#fff",
    fontFamily: "DM Sans",
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 400
},
emptyIcon: {
    width: "64px",
    height: "64px",
},buttonCreate: {
  display: "flex",
  alignItems: "center",
  gap: "8px",
  padding: "10px 16px",
  backgroundColor: "#684EF3",
  borderRadius: "8px",
  marginTop: "24px",
cursor:"pointer"
} as React.CSSProperties,
emptyText: {
    color: "#5E6671",
    fontFamily: "DM Sans",
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 400,
    cursor:"pointer"
},
emptyWorkspace: {
    display: "flex",
    flexDirection: 'column',
    alignItems: "center",
    justifyContent: "center",
    background: `url(${emptyBackground}) no-repeat center center fixed`,
    height: "90%",
    border: "1px solid #E2E8F0",
    borderRadius: "16px",
    margin: "20px 40px"
} as React.CSSProperties,
  modalButtonViewStyle: {
    display: "flex",
    justifyContent: "space-between",
    padding: "40px",

  },
  tableViewStyle: {
    width: "100%",
    overflow: "hidden",
    borderRadius:"16px"
  },
  tableContainerStyle: {
    maxHeight: 600,
  },
  tableButtonViewStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  headerButtonViewStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  secondButtonViewStyle: {
    marginLeft: 10,
  },
  btnAddAccounts: {
    backgroundColor: "#005500",
    marginLeft: 10,
  },
  btnDeleteAccounts: {
    backgroundColor: "#990000",
    marginLeft: 10,
  },
  checkCircleRed: {
    height: 10,
    width: 10,
    backgroundColor: "red",
    borderRadius: 5,
  },
  innerTableBox: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  arrow: {
    height: 20,
    width: 20,
    resizeMode: "contain",
  },
  dropdownListContainer: {
    maxHeight: 200,
    overflow: "auto",
    width: 300,
  },
  errorMsg: {
    color: "red",
    margin: 10,
  },
};
// Customizable Area End
