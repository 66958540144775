// Customizable Area Start
import React from "react";
import BulkUploadDashboardDialogController, { configJSON, listAction } from "./BulkUploadDashboardDialogController.web";
import { Dialog, DialogContent, FormControl, IconButton, InputAdornment, MenuItem, TextField } from "@material-ui/core";
import { SelectComponent } from "../../../../packages/blocks/accountgroups/src/WorkspaceList.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { sampleFile, upload } from "./assets";
import Dropzone from 'react-dropzone';
import { MenuFilterItem } from "../../../../packages/components/src/MenuFileItem.web";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";

export const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: {id: number; name: string }) => option.name,
});

export class BulkUploadDashboardDialog extends BulkUploadDashboardDialogController {
    renderDropdownPurpose = () => {
        return (
            <FormControl variant="filled">
                <SelectComponent
                    data-test-id={`renderDropdownPurpose`}
                    value={this.state.uploadChoice}
                    className={this.props.classes?.customdropdown}
                    IconComponent={() => <div />}
                    endAdornment={(
                        <InputAdornment position="end" className={this.props.classes?.viewIconDropdown}>
                            <ExpandMoreIcon style={webStyles.iconExpand} />
                        </InputAdornment>
                    )}
                    displayEmpty={true}
                    MenuProps={{
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                    }}
                >
                    {listAction.map((item) =>
                        <MenuItem
                            className={this.props.classes?.dropdownitem}
                            key={item.name}
                            value={item.name}
                            data-test-id={`item_${item.name}`}
                            onClick={() => this.onChangeAction(item.name)}
                        >
                            {item.name}
                        </MenuItem>
                    )}
                </SelectComponent>
            </FormControl>
        )
    }

    renderWorkspaceChoice = () => {
        return (
            <Autocomplete
                data-test-id="autocomplete"
                options={this.state.listWorkspace}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={configJSON.workspacePlaceholder}
                        variant="outlined"
                        InputProps={{
                            ...params.InputProps,
                            style: webStyles.placeholderButtonShowWorkspace,
                        }}
                    />
                )}
                onChange={(event, inputVal) => {
                    if (inputVal) this.onChangeWorkspace(inputVal)
                }}
                renderOption={(option) => (
                    <div style={webStyles.optionItem}>{option.name}</div>
                )}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.name === value.name}
                filterOptions={filterOptions}
            />
        )
    }

    renderFileItem = (file: File, index: number) => {
        return (
            <div key={`file-${index}`} style={webStyles.viewFileItem}>
                {this.renderIconFile(file)}
                <div style={webStyles.fileInfoView}>
                    <span style={webStyles.nameFileText}>{file.name}</span>
                    <span style={webStyles.byteFileText}>{this.byteConverter(file.size)}</span>
                </div>
                <MenuFilterItem 
                    index={index}
                    classes={this.props.classes}
                    configJSON={configJSON}
                    onReplaceFile={this.onReplaceFile}
                    onRemoveFile={this.onRemoveFile}
                />
            </div>
        )
    }

    renderIconFile = (file: File) => {
        if (file.type.includes("image")){
            const filePreview = {
                ...file,
                preview: URL.createObjectURL(file),
            }
            return <img src={filePreview.preview} style={webStyles.fileIcon} />
        } else {
            return <img src={sampleFile} style={webStyles.fileIcon} />
        }
    }

    render() {
        const { isDialogOpen, } = this.props
        return (
            <Dialog
                onClose={() => this.closeDialog()}
                aria-labelledby="customized-dialog-title"
                open={isDialogOpen}
                fullWidth
                PaperProps={{
                    style: webStyles.viewDialog
                }}
            >
                <DialogContent
                    className="dialog-address-content"
                    style={{ padding: 0 }}
                >
                    <div style={webStyles.textTitle}>
                        {configJSON.uploadFilesText}
                        <svg data-test-id="closeIcon" onClick={this.closeDialog} style={webStyles.closeIcon} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.192 10.3438L15.949 14.5858L11.707 10.3438L10.293 11.7578L14.535 15.9998L10.293 20.2418L11.707 21.6558L15.949 17.4137L20.192 21.6558L21.606 20.2418L17.364 15.9998L21.606 11.7578L20.192 10.3438Z" fill="#30353B" />
                        </svg>
                    </div>

                    <Dropzone onDrop={this.onDrop}>
                        {({ getRootProps, getInputProps }) => (
                            <section className="container">
                                <div {...getRootProps({ className: 'dropzone' })}>
                                    <input {...getInputProps()} />
                                    <div style={webStyles.uploadContainer}>
                                        <img src={upload} style={webStyles.img} />
                                        <span style={webStyles.dragAndDropText}>{configJSON.dragText}</span>
                                        <div data-test-id="uploadFile" style={webStyles.browseButton}>{configJSON.browseText}</div>
                                    </div>
                                </div>
                                <div style={webStyles.listFileView}>{this.state.files.map((file, index) => this.renderFileItem(file, index))}</div>
                            </section>
                        )}
                    </Dropzone>
                    <div style={webStyles.viewInput}>
                        <span style={webStyles.titleTextInput}>{configJSON.purposeTitleText}</span>
                        {this.renderDropdownPurpose()}
                    </div>
                    {
                        this.state.uploadChoice === "Upload file with no action required" && (
                            <div style={webStyles.viewInput}>
                                <span style={webStyles.titleTextInput}>{configJSON.workspaceChoice}</span>
                                {this.renderWorkspaceChoice()}
                            </div>
                        )
                    }
                    <div
                        data-test-id="continueButton"
                        style={{...webStyles.okButton, ...{ backgroundColor: this.validationButton() ? "#684EF3" : "#94A3B8" }}}
                        onClick={this.onContinue}
                    >
                        {configJSON.continueText}
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

const webStyles = {
    viewDialog: {
        borderRadius: '24px',
        border: '0px solid #D0D2DA',
        maxHeight: "calc(100% - 80px)",
        width: "33%",
        maxWidth: "unset",
        backgroundColor: "#fff"
    } as React.CSSProperties,
    textTitle: {
        color: "#14101E",
        fontFamily: "DM Sans",
        fontSize: "24px",
        fontWeight: 400,
        lineHeight: "33.6px",
        padding: "24px 32px",
        borderBottom: '1px solid #F6F5F9',
        backgroundColor: "#fff",
        position: "sticky"
    } as React.CSSProperties,
    closeIcon: {
        width: "32px",
        height: "32px",
        position: "absolute",
        right: "20px"
    } as React.CSSProperties,
    browseButton: {
        color: "#684EF3",
        fontFamily: "DM Sans",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        cursor: "pointer"
    },
    uploadContainer: {
        backgroundColor: "#EAE6FF",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        border: "2px dashed #9B93F6",
        borderRadius: "16px",
        paddingTop: "16px",
        paddingBottom: "20px",
        gap: "8px",
        margin: "24px 32px 16px 32px"
    } as React.CSSProperties,
    menuStyle: {
        transform: 'translateX(10px) translateY(50px)',
        borderRadius: "8px",
    },
    fileInfoView: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        minWidth: 0
    } as React.CSSProperties,
    listFileView: {
        display: "flex",
        flexDirection: "column",
    } as React.CSSProperties,
    nameFileText: {
        color: "#14101E",
        fontFamily: "DM Sans",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "21px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        width: "100%",
        whiteSpace: "nowrap",
    } as React.CSSProperties,
    byteFileText: {
        color: "#30353B",
        fontFamily: "DM Sans",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "21px",
    },
    fileIcon: {
        width: "56px",
        height: "56px",
    },
    viewFileItem: {
        display: "flex",
        alignItems: "center",
        gap: "12px",
        padding: "12px",
        margin: "0 32px",
        marginBottom: "8px",
        borderRadius: "8px",
        backgroundColor: "#f6f5f9"
    } as React.CSSProperties,
    iconMenu: {
        width: "18px",
        height: "18px",
        marginRight: "8px"
    },
    optionItem: {
        color: "#30353B",
        fontFamily: "DM Sans",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "21px",
    },
    dragAndDropText: {
        color: "#30353B",
        fontFamily: "DM Sans",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "21px",
    },
    viewButton: {
        display: "flex",
        gap: "16px",
        marginTop: "24px",
        marginBottom: "32px",
        padding: "0 32px"
    },
    iconExpand: {
        width: "24px",
        height: "24px",
    },
    img: {
        width: "48px",
        height: "48px",
    },
    viewInput: {
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        padding: "0 32px",
        marginBottom: "20px"
    } as React.CSSProperties,
    titleTextInput: {
        fontFamily: "DM Sans",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "21px",
        color: "#171B21"
    },
    placeholderButtonShowWorkspace: {
        fontFamily: "DM Sans",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#14101E",
    },
    okButton: {
        borderRadius: "8px",
        color: "#fff",
        fontFamily: "DM Sans",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        paddingTop: "10px",
        paddingBottom: "10px",
        textAlign: "center",
        margin: "24px 32px 32px 32px",
        cursor: "pointer"
    } as React.CSSProperties,
}

export default BulkUploadDashboardDialog;
// Customizable Area End